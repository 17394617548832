<template>
  <div />
</template>

<script>
export default {
    created() {
        window.open('/manager-scheduler/user', '_blank')
        this.$router.go(-1)
    }
}
</script>

<style scoped>

</style>